import {appApiUrl, getCanonicalUrl, getHreflang} from "./config";

export const formatPrice = (number, currency) => {
    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency || "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(number);
}
export const formatPriceStatic = (number, currency) => {
    const Currency_Symbol = {
        USD: "$",
        CAD: "$",
        GBP: "£",
        AUD: "$",
    };
    return Currency_Symbol[currency] + number.toLocaleString();
}

export function centsToDollar(cents) {
    return (cents / 100).toFixed(2);
}

export function removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
}

export function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function stopWatch(cmd) {
    if (cmd === "start") {
        var startTime = new Date().getTime();
        sessionStorage.setItem("startTime", startTime);
    } else if (cmd === "stop") {
        var stopTime = new Date().getTime();
        sessionStorage.setItem("stopTime", stopTime);
    } else if (cmd === "get") {
        var stopTime = sessionStorage.getItem("stopTime");
        var startTime = sessionStorage.getItem("startTime");
        if (startTime && stopTime) {
            var total_time = (stopTime - startTime) / 1000;
        }
        return total_time;
    } else if (cmd === "reset") {
        sessionStorage.setItem("stopTime", 0);
        sessionStorage.setItem("startTime", 0);
    }
}

export async function retailAPI(request_data) {
    try {
        let d = await fetch(
            `${appApiUrl}/retail_api/`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(request_data),
            }
        );
        let r = await d.json();
    } catch (err) {
        console.log(err);
    }
}

export function getSubdomain() {
    const host = window.location.hostname;
    const parts = host.split('.');
    return new Promise((resolve, reject) => {
        if (parts.length > 2) {
            resolve(parts[0])
        } else {
            reject(null);
        }
    })
}

export const createCanonicalUrl = () => {
    const {protocol, hostname, pathname} = window.location;

    const canonicalUrl = `${protocol}//${hostname}${pathname}`;
    let linkElement = document.querySelector("link[rel='canonical']");
    if (linkElement) {
        linkElement.setAttribute('href', canonicalUrl);
    } else {
        linkElement = document.createElement('link');
        linkElement.setAttribute('rel', 'canonical');
        linkElement.setAttribute('href', canonicalUrl);
        document.head.appendChild(linkElement);
    }
}

export const setCanonicalUrl = (type = 'splash', productId = '') => {
    const canonicalUrl = getCanonicalUrl(type, productId);

    const linkElement = document.querySelector("link[rel='canonical']");
    if (linkElement) {
        linkElement.setAttribute('href', canonicalUrl);
    } else {
        const canonicalLink = document.createElement('link');
        canonicalLink.setAttribute('rel', 'canonical');
        canonicalLink.setAttribute('href', canonicalUrl);
        document.head.appendChild(canonicalLink);
    }
}

export const setHreflang = (type = 'splash', productId = '') => {
    const {hreflang, domain} = getHreflang(type, productId);

    const linkElement = document.querySelector("link[rel='alternate']");
    if (linkElement) {
        linkElement.setAttribute('hreflang', hreflang);
        linkElement.setAttribute('href', domain);
    } else {
        const newLink = document.createElement('link');
        newLink.setAttribute('rel', 'alternate');
        newLink.setAttribute('hreflang', hreflang);
        newLink.setAttribute('href', domain);
        document.head.appendChild(newLink);
    }
}
