import React, {Fragment, useEffect, useState} from 'react';
import {useOrder} from '../../../contexts/OrderContext';
import {usePage} from '../../../contexts/PageContext';
import './CheckoutTab.View.scss';
import {appApiUrl} from '../../../utils/config';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import CheckoutContent from './CheckoutContent.View';
import {Loader} from '../../ui/loader';

const stripePromise = loadStripe('pk_test_51IdiDtFh7Dasx8MokymoBsEmIltaHNS1Rv1wfTzwJ8LB0ndtWHyuPccrv4r46xEd7ITa2HbEo9Qci6xZXMadIO7C00KVTZEEwl');

const defaultAppearance = {
    theme: 'stripe',
    labels: 'floating',
    rules: {
        '.Input:focus': {
            boxShadow: 'none',
            outline: 0,
            borderColor: '#000000',
        },
    },
};

const CheckoutTab = () => {
    const {clientSecret, setClientSecret, country} = usePage();
    const {cartItems} = useOrder();

    const [loading, setLoading] = useState(true);
    const [appearance, setAppearance] = useState({
        time: new Date().getTime(),
        data: defaultAppearance,
    });

    useEffect(() => {
        setAppearance((prev) => ({
            ...prev,
            time: new Date().getTime(),
        }));
    }, []);

    const fetchClientSecret = async () => {
        if (!cartItems?.length) return;

        setLoading(true);

        try {
            setClientSecret('');
            const metadata = cartItems.map(({art_id, art_name, artist_name, quantity, itemSize: artsize}) => ({
                artsize, art_id, art_name, artist_name, quantity,
            }));

            const totalPrice = cartItems.reduce((acc, curr) => acc + curr.price * curr.quantity, 0);

            const response = await fetch(`${appApiUrl}/create-payment-intent/`, {
                headers: {'Content-Type': 'application/json'},
                method: 'POST',
                body: JSON.stringify({
                    amount: totalPrice,
                    active_country: country || 'us',
                    metadata,
                }),
            });

            const data = await response.json();
            setClientSecret(data?.resp?.client_secret);
        } catch (error) {
            console.error('Error fetching client secret:', error);
            alert('Unable to process your payment. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchClientSecret();
    }, [cartItems, country]);

    return (
        <Fragment>
            {loading ? (
                <Loader/>
            ) : (
                <Fragment>
                    {stripePromise && clientSecret ? (
                        <div key={appearance.time}>
                            <Elements stripe={stripePromise} options={{clientSecret, appearance: appearance.data}}>
                                <CheckoutContent/>
                            </Elements>
                        </div>
                    ) : (
                        'Failed to load Stripe Element!'
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default CheckoutTab;
