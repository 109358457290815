import {AddressElement, LinkAuthenticationElement, PaymentElement} from '@stripe/react-stripe-js';
import React, {useState} from 'react';
import {Col, Form} from 'react-bootstrap';
import {usePaymentInputs} from 'react-payment-inputs';
import './CheckoutPayment.View.scss';

const CheckoutPayment = (props) => {
    const {
        setEmail,
        setPhoneNumber,
        setName,
        setCountry,
        setAddressLine1,
        setCity,
        setPostalCode,
    } = props;
    const [activeTab, setActiveTab] = useState('card');
    const {meta, getCardNumberProps, getExpiryDateProps, getCVCProps} = usePaymentInputs();
    const {erroredInputs, touchedInputs} = meta;

    return (
        <div>
            <div className='checkout-payment-container'>
                {activeTab === 'card' ? (
                    <div>
                        <h2 className='info-header'>Contact info</h2>
                        <LinkAuthenticationElement
                            onChange={(event) => {
                                setEmail(event?.value?.email);
                            }}
                        />
                        <h2 className='info-header-sub'>Shipping Address</h2>

                        <AddressElement
                            onChange={(e) => {
                                setCountry(e?.value?.address?.country);
                                setAddressLine1(e?.value?.address?.line1);
                                setCity(e?.value?.address?.city);
                                setPostalCode(e?.value?.address?.postal_code);
                                setName(e?.value?.name);
                                setPhoneNumber(e?.value?.phone);
                            }}
                            options={{
                                mode: 'shipping',
                                fields: {
                                    phone: 'always',
                                },
                                validation: {
                                    phone: {
                                        required: 'always',
                                    },
                                },
                            }}
                        />

                        <h2 className='info-header-sub'>Payment</h2>
                        <PaymentElement/>
                    </div>
                ) : (
                    <div>
                        <Form.Group>
                            <Form.Label className='input-label' column="sm">Card number</Form.Label>
                            <Form.Control
                                {...getCardNumberProps()}
                                isInvalid={touchedInputs.cardNumber && erroredInputs.cardNumber}
                                placeholder='0000 0000 0000 0000'
                            />
                            <Form.Control.Feedback type='invalid'>
                                {erroredInputs.cardNumber}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Row>
                            <Form.Group as={Col} style={{maxWidth: '200px'}}>
                                <Form.Label className='input-label' column="sm">Expiry date</Form.Label>
                                <Form.Control
                                    {...getExpiryDateProps()}
                                    isInvalid={touchedInputs.expiryDate && erroredInputs.expiryDate}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {erroredInputs.expiryDate}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} style={{maxWidth: '150px'}}>
                                <Form.Label className='input-label' column="sm">CVC</Form.Label>
                                <Form.Control
                                    {...getCVCProps()}
                                    isInvalid={touchedInputs.cvc && erroredInputs.cvc}
                                    placeholder='123'
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {erroredInputs.cvc}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CheckoutPayment;
