import "./Loader.scss";

export const Loader = () => {
    return (
        <div className="growing-dot-loader">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </div>
    );
};
