import {createContext, useContext, useEffect, useState} from 'react';
import {useSessionStorage} from '../hooks/useSessionStorage';
import {v4 as uid} from 'uuid';

const OrderContext = createContext(null);
export const useOrder = () => useContext(OrderContext);
const OrderProvider = ({children}) => {
    const [cartItems, setCartItems] = useSessionStorage('cartItems', []);
    const [priceDetails, setPriceDetails] = useState();
    const [loadingPrice, setLoadingPrice] = useState(false);

    const [totalCartItemsPrice, setTotalCartItemsPrice] = useSessionStorage(
        'totalCartItemPrice',
        0
    );
    const [totalCartItems, setTotalCartItems] = useSessionStorage(
        'totalCartItems',
        0
    );
    const isAlreadyInCart = (id) => cartItems.find((i) => i.art_id === id);
    useEffect(() => {
        let totalPrice = cartItems.reduce((acc, curr) => {
            acc += curr.price * curr.quantity;
            return acc;
        }, 0);
        let totalItems = cartItems.reduce((acc, curr) => {
            acc += curr.quantity;
            return acc;
        }, 0);
        setTotalCartItems(totalItems);
        setTotalCartItemsPrice(totalPrice);
    }, [cartItems]);
    const removeFromCart = (id, swiper) => {
        setCartItems((prev) => prev.filter((item) => item.id !== id));
        // swiper.update();
    };
    const addToCart = (item) => {
        const found = cartItems.findIndex(
            (i) =>
                i.art_id === item.art_id &&
                i.art_name === item.art_name &&
                i.itemSize === item.itemSize
        );

        if (found !== -1) {
            const newCartItems = cartItems;
            cartItems[found].quantity += 1;
            setCartItems(newCartItems);
        } else {
            item.id = uid();
            setCartItems((prev) => [...prev, {...item, quantity: 1}]);
        }
    };
    const value = {
        cartItems,
        setCartItems,
        totalCartItemsPrice,
        isAlreadyInCart,
        removeFromCart,
        addToCart,
        priceDetails,
        setPriceDetails,
        loadingPrice,
        setLoadingPrice,
        totalCartItems,
    };
    return (
        <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
    );
};

export default OrderProvider;
